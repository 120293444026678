import { useQuery } from "@apollo/client"
import gql from "graphql-tag"
import { Link } from "react-router-dom"
import moment from "moment"
import React, { useEffect } from "react"
import { UserRolePills } from "../user/UserRolesField"
import { useT } from "../../common/i18n"
import GenericAntDataTable from "../../common/ui/genericDataTable/genericAntDataTable"
import { useDebouncedPagination } from "../../common/hooks/useDebouncedPagination"

const QUERY_GET_USER_LIST = gql`
      query ($page: PaginationInputType, $orgId: ID) {
          getUserList(page: $page, sort: { field: "createdAt", direction: "DESC" }, orgId: $orgId) {
              id
              login
              organisation {
                  id
              }
              baseOrganisation {
                  id
              }
              createdAt
              roles
              email
              lastLogin
          }
      }
  `


export const OrganisationUserList = (props) => {
    const t = useT()
    const basePath = props.basePath
    const organisationId = props.organisationId

    const usersListResult = useQuery(QUERY_GET_USER_LIST,
      {
          fetchPolicy: "no-cache",
          notifyOnNetworkStatusChange: true,
          variables: {
              orgId: organisationId,
          },
      })

    const userCountResult = useQuery(
        gql`
            query ($orgId: ID) {
                getUserCount(orgId: $orgId, withSubOrgs: false)
            }
        `,
        {
            variables: {
                orgId: organisationId,
            },
        }
    )

    const page = useDebouncedPagination(100, usersListResult.refetch)

    useEffect(() => {
        if (userCountResult?.data?.getUserCount) {
            page.setTotal(userCountResult?.data?.getUserCount)
        }
    }, [userCountResult?.data?.getUserCount])

    let tableConfig = {
        id: "defaultTableConfig_58592039-6f09-495b-8d49-6ef89098efa8",
        cols: [
            {
                id: "defaultCell_cf4fb552-19a5-414c-bfb6-ccc885bac87e",
                heading: "ID",
                width: "2rem",
                cell: {
                    customRender: (item) => {
                        return <Link to={basePath + `${item.id}`}>{item.id}</Link>
                    },
                    isCustomRender: true,
                },
                defaultVisible: true,
            },
            {
                id: "defaultCell_fb2740ac-7391-461f-8aea-561ad3f74fe3",
                heading: t("user.login", "Login"),
                cell: {
                    customRender: (item) => {
                        return <Link to={basePath + `${item.id}`}>{item.login}</Link>
                    },
                    isCustomRender: true,
                },
                defaultVisible: true,
            },
            {
                id: "defaultCell_c9fa2701-7e60-478b-bae5-85dc4de356a5",
                heading: "E-Mail",
                cell: {
                    format: "{{email}}",
                },
                defaultVisible: true,
            },
            {
                id: "defaultCell_82f017a9-bf86-41b9-a6f3-93e7e31dcedf",
                heading: t("user.roles", "Roles"),
                cell: {
                    customRender: (item) => {
                        return <UserRolePills roles={item.roles} />
                    },
                    isCustomRender: true,
                },
                defaultVisible: true,
            },
            {
                id: "defaultCell_936047c5-c8a1-404f-9da4-f6aadd8071f4",
                heading: t("user.created", "Created"),
                cell: {
                    customRender: (item) => {
                        return (
                            <div className="slds-cell-wrap">
                                {moment(item.createdAt).fromNow()} ({moment(item.createdAt).format("DD.MM.YYYY HH:mm:ss")})
                            </div>
                        )
                    },
                    isCustomRender: true,
                },
                defaultVisible: true,
            },
            {
                id: "defaultCell_a8bd26c5-0fe0-46f3-b3d9-0c30eb4b0169",
                heading: t("user.last-login", "Last Login"),
                cell: {
                    customRender: (item) => {
                        return <div className="slds-cell-wrap">{lastLoginCell(item?.lastLogin)}</div>
                    },
                    isCustomRender: true,
                },
                defaultVisible: true,
            },
        ],
    }

    return (
        <div style={{ maxWidth: "100vw", paddingRight: "1vw" }}>
            <GenericAntDataTable
                id={"user-table"}
                selectRows={false}
                items={usersListResult?.data?.getUserList}
                tableConfigDefault={tableConfig}
                gqlResult={usersListResult}
                page={page}>
            </GenericAntDataTable>
        </div>
    )
}

function lastLoginCell(lastLogin) {
    if (lastLogin) {
        return `${moment(lastLogin).fromNow()} ${moment(lastLogin).format("DD.MM.YYYY HH:mm:ss")}`
    } else {
        return "never"
    }
}
